.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes wave-animation {
  0% { transform: rotate( 0.0deg) }
  10% { transform: rotate(14.0deg) }  
  20% { transform: rotate(-8.0deg) }
  30% { transform: rotate(14.0deg) }
  40% { transform: rotate(-4.0deg) }
  50% { transform: rotate(10.0deg) }
  60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
  100% { transform: rotate( 0.0deg) }
}

.handEmoji {
  font-size: 80%;
  padding-right: 1rem;

  animation-name: wave-animation;
  animation-delay: 2s;
  animation-duration: 2.5s;
  transform-origin: 70% 70%;
  display: inline-block;
  margin-left: 3px;
}

.aboveTheFold {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 90vh;
  height: -webkit-fill-available;
  max-width: 500px;
  width: 90vw;
}

.shivangImage {
  position: relative;
  right: 5px;
  // height: 50vh;
  // aspect-ratio: 0.61;
}

.name {
  font-size: 200%;
  font-weight: 200;
  text-align: center;
}

.occupation {
  font-size: 200%;
  text-align: center;
  margin-top: 20%;

  p {
    margin: 0;
  }
}

.skills {
  margin-top: 10vh;
  margin-bottom: 10vh;
}
