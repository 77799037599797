.skillsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
  li {
    display: block;
    margin: auto;
    padding: 8px 10px;
    
    div {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 200px;

      p {
        padding-left: 16px;
        font-size: 16px;
      }
    }
  }
}

.skillTitle {
  font-size: 200%;
  text-align: center;
  margin-top: 0;
}